.nav{
    height:100%;
    width: 87%;
    align-items: center;
    display: flex;
    justify-content: center;
    border: 3px solid black;
   
    
    
}
.nav-content{
    width: 80%;
    display: flex;
    justify-content: center;
}
.nav-clock{
    width: 20%;
    /* height: 100%;
    background-color: white; */
    display: flex;
    justify-content: center;
    align-items: center;
}