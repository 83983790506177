@media (max-width: 576px) {
  .table-main {
    font-size: 10px;
  }

  .inputs-div {
    height: 75vh !important;
  }

  .bus-input-div {
    height: 60vh !important;
  }
}

.announce-button {
  width: 20%;
  transition: 0.6s ease;
  overflow: hidden;
  color: black;
  border-radius: 10px;
}

/* Apply animation on hover */
.announce-button:hover {
  color: white;
  background-color: #053675;
}

.delete-button {
  transition: 0.4s ease;
  border-radius: 10px;
}

.delete-button:hover {
  color: white;
  background-color: rgb(168, 16, 16);
  box-shadow: 0 0 10px grey;
}
.main-announce {
  background-image: url(../../assets/loginImg.jpg);
}


.loader {
  height: 23px;
  aspect-ratio: 2.5;
  --_g: no-repeat radial-gradient(farthest-side,#fff 80%,#000);
  background:var(--_g), var(--_g), var(--_g), var(--_g);
  background-size: 20% 50%;
  animation: l43 1s infinite linear; 
}
@keyframes l43 {
  0%     {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
  16.67% {background-position: calc(0*100%/3) 0   ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
  33.33% {background-position: calc(0*100%/3) 100%,calc(1*100%/3) 0   ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
  50%    {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 100%,calc(2*100%/3) 0   ,calc(3*100%/3) 50% }
  66.67% {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 100%,calc(3*100%/3) 0   }
  83.33% {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 100%}
  100%   {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
}