.main-layout {
    display: flex;
    flex-direction: column;
}

.main-layout>.content {
    flex: 1;
}

.main-layout>.content {
    margin-top: auto;
}

/* .date-input {
    position: relative;
    width: 20px;
    height: 24px;
    opacity: 1;
    outline: none;
} */