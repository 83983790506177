.bus-point {
  height: 100%;
  width: 100%;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom-main {
  flex-direction: column;
  display: flex;
  background: rgb(255, 128, 128);
  background: radial-gradient(
    circle,
    rgb(255, 128, 128) 0%,
    rgb(177, 0, 0) 50%
  );

  height: 80vh;
  width: 100%;
}
.text-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  background: white;
  height: 80%;
  border-radius: 0px 90px 0px 90px;
}

.bus-outer-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 100%;
  overflow: hidden;
}

.bus-image {
  height: 200px;
  width: 200px !important;
  animation: slide 10s linear infinite;
}
@keyframes slide {
  0% {
    /* width: 100%; */
    margin-left: 100%;
  }
  100% {
    /* width: 100%; */
    margin-left: 0;
  }
}
.mySwiper {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */ 
  width: 38vh;
  height: 70vh;
  /* margin-left: 5px;
  margin-right: 5px; */
  /* height: fit-content; */
  /* overflow: hidden; */
}
.mySwiper1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.swiper-slide {
  height: 13vh;
  overflow: hidden;
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: space-evenly;
}

.swiper-div {
  height: 100%;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.swiper-div1 {
  height: 100%;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
.swiper-div-left {
  /* height: 0%; */
  margin-bottom: 25px;
  margin-right: 25px;
  width: 20%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 15px;
  /* justify-content: center; */
}
.swiper-div-right {
  height: 100%;
  width: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.right-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: white;
  height: 100%;
  border-radius: 0px 90px 0px 90px;
  overflow: hidden;
}

/* for the buses  */
.right-text1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* color: white; */
  background: rgb(206, 203, 203);
  height: 100%;
  border-radius: 0px 90px 0px 90px;
  overflow: hidden;
}
.bus-inner-div {
  height: 15vh;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
}
.bus-head {
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
  font-size: large;
}
.slidermain{
  direction: rtl;
}

@media (min-width: 1440px) {
  body {
    font-size: 20px;
  }
  .swiper-slide {
    height: 13vh;
    overflow: hidden;
    width: 100%;
    display: flex;
    font-size: 20px;
    align-items: center;
    justify-content: space-evenly;
  }

}

.slider{
  /* background-color: white; */
  width: 100%;
  height: 20%;
  margin-bottom: 23px;
}