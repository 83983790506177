/* CSS */
.class-main {
    max-height: 95%;
    height: 100%;
    width: 40%;
    background-color: white;
    border: 2px solid black;
    border-radius: 70px 0px 70px 0px;
    overflow: hidden;
}

.class-head {
    width: 100%;
    border-bottom: 2px solid black;
    text-align: center;
}

.class-student-name {
    height: 90%;
    width: 100%;
}

.students-names-div {
    width: 100%; 
    overflow: hidden; 
}

table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    font-size: 18px;
    border: none; 
}

td {
    padding: 8px;
}

.class-student-name table tr {
    display: flex;
    flex-wrap: wrap;
}

.class-student-name table td {
    flex-basis: 50%;
}

.class-student-name table td {
    border-right: 2px solid black;
    border-bottom: 2px solid black;
}


