/* body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
   
    background: white;
  
  }
  
  .main-container {
    overflow: hidden;
    height: 100vh;
    width: 100%;
  }
  
  .main-container-center {
    height: 74vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    border: 2px solid black;
  } */
  
  .popup{
    height: 70%;
    width: 40%;
    border: 2px solid black;
    display: flex;
    position: absolute;
    background-image: url('/src/assets/sports\ announce.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 40% 40%;
    margin-left: 30%;
    border: 2px solid black;
    border-radius: 10%;
    animation: fade 10s linear   ;
    display: none;
  }
  @keyframes fade {
    0%{
      opacity: 0;
    }
    50%{
      opacity: 1;
    }
    100%{
      opacity: 0;
    }
  }
  .popup.show{
    display: block;
  }