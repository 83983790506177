@tailwind base;
@tailwind components;
@tailwind utilities;

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .sidebar {
    height: calc(100vh - 64px); /* Adjust the height as needed */
  }
}

/* For devices with a width of 767px or less (phones) */
@media only screen and (max-width: 767px) {
  .sidebar {
    height: calc(100vh - 75px);
  }
}

.sidebar {
  height: calc(100vh - 56px); 
  /* height: ; */
  width: 250px;
  position: fixed;
  top: 64px;
  left: 0;
  z-index: 1000;
  background-color: #1b1e25;
  overflow-y: auto;
  transition: transform 0.5s ease-in-out;
}

.hide {
  transform: translateX(-250px);
}

.show {
  transform: translateX(0);
}

.custom-number-input::-webkit-outer-spin-button,
.custom-number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
