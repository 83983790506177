.main-left {
  height: 100%;
  width: 65%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("/src/assets/school1.jpg");
  background-size: cover;
  background-position: 50% 50%;
  animation: changeimage 30s infinite;
}

@keyframes changeimage {
  0% {
    background-image: url("/src/assets/school1.jpg");
    background-size: cover;
    background-position: 60% 60%;
  }

  25% {
    background-image: url("/src/assets/school3.jpg");
    background-size: cover;
    background-position: 50% 50%;
  }

  50% {
    background-image: url("/src/assets/school4.jpg");
    background-size: cover;
    background-position: 50% 50%;
  }

  75% {
    background-image: url("/src/assets/school1.jpg");
    background-size: cover;
    background-position: 50% 50%;
  }

  100% {
    background-image: url("/src/assets/school4.jpg");
    background-size: cover;
    background-position: 50% 50%;
  }
}
